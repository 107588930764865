import axios from "axios";
//import { setAlert } from './alert';
//import {  useHistory } from "react-router-dom";
import {
  GET_BUTTON,
  GET_BUTTONS,
  ADD_BUTTON,
  CLEAR_BUTTON,
  UPDATE_BUTTON,
  AUTH_ERROR,
  AUTH_ERRORS,
  GET_VALIDATION_HEADER,
  GET_INTEGRATION_HEADER,
  GET_BUTTON_SETTING_HEADER,
  GET_VIEW_DOC_HEADER,
  //DELETE_BUTTON,
  GET_REFERENCE_HEADER,
  REMOVE_BUTTON_ALERT,
  GET_API_BUTTON,
  LOAD_BUTTON,
  EMPTY_BLANK_URL,
  REMOVE_AUTH_ERROR,
  GET_MASTERFIELDDATA,
  GET_EMAILREMINDER_DATA,
  TEST_EMAILREMINDER,
  LOGOUT,
  CLEAR_PROFILE,
  LOAD_COUNTRY_LIST,
  GET_COUNTRY_LIST,
  ERR_COUNTRY_LIST,
  LOAD_COUNTRY_LINKS,
  GET_COUNTRY_LINKS,
  ERR_COUNTRY_LINKS,
  RESET_COUNTRY,
  RESET_DIRECT_LINK,
  SET_ALPHA_CODE,
  SET_CATEGORY,
  BUTTON_DELETE,
  BUTTON_DEL_SUCCESS,
  GET_TRIGGER_HEADER,
  TRIGGER_COMPARE_VERIFICATION,
  DELETE_TABLE_DATA_SUCCESS,
  UPDATE_TABLE_DATA,
  CREATE_SOURCE
} from "./types";
import { sendLogs } from "../actions/logs";
import { env as environment } from "./environment";
//import setAuthToken from '../utils/tools';
import refreshAuth from "./refreshAuth";
import ls from "localstorage-slim";
import { GlobalDebug } from "../remove-consoles";
import { access } from "fs";

if (!environment.consoleLog) {
  GlobalDebug(false);
}

ls.config.encrypt = true;

export const logoutt = () => (dispatch) => {
  sendLogs(
    "Org Logout",
    "Logged out successfully and profile cleared",
    "actions/auth.js"
  );
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
console.log("ls.get(token) ", axios.defaults.headers.common["Authorization"]);
console.log("ls.get(token) ", localStorage.getItem("token"));
function isAlphanumeric(str) {
  return /^[a-zA-Z0-9]+$/.test(str);
}
for (var i in localStorage) {
  console.log(i + " = " + localStorage[i]);
  console.log(isAlphanumeric(localStorage[i]));
  if (i === "apikey") {
    const apiVal = isAlphanumeric(localStorage[i]);
    if (apiVal === true) {
      logoutt();
    } else {
      console.log("hello123");
    }
  }
}
// if (localStorage.getItem("token") || ls.get("token") == '') {
// 	logoutt();
// }
//var apikey = ls.get('apikey');
//delete axios.defaults.headers.common["Authorization"];
axios.defaults.headers.common["Authorization"] = ls.get("token");
// Get buttons
let getbuttonsCount = 0;
export const getbuttons = () => async (dispatch) => {
  console.log('get buttons')
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  dispatch({ type: CLEAR_BUTTON });
  var json = { apikey: ls.get("apikey") };
  try {
    const res = await axios.post(environment.invite, json);
    sendLogs("getbuttons", "getbuttons success", "actions/button.js");
    //console.log("getbuttons " + res.data + res.data.error);
    if (res.data) {
      getbuttonsCount = 0;
    }
    dispatch({
      type: GET_BUTTONS,
      payload: res.data,
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getbuttonsCount < 5
    ) {
      getbuttonsCount++;
      dispatch(refreshAuth("getbuttons", null, null, false));
    } else {
      getbuttonsCount = 0;
      console.log("auth err 1: " + err);
      sendLogs("getbuttons Failed", err.message, "actions/button.js");
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};
let getbuttonCount = 0;
export const getbuttonslist = () => async (dispatch) => {
  console.log("getbuttonslist called");
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  dispatch({ type: CLEAR_BUTTON });
  var json = { apikey: ls.get("apikey") };
  try {
    const res = await axios.post(environment.btn_list, json);
    console.log("btn_list==>" + JSON.stringify(res.data));
    console .log("RISHABH STATUS"+res.status);
    sendLogs("getbuttonsList", "getbuttonsList success", "actions/button.js");
    if (res.data) {
      getbuttonCount = 0;
    }
    // dispatch({
    //   type: GET_BUTTONS,
    //   payload: res.data,
    // });
  } catch (err) {
    //handle catch 
    console.log("errorrrrr", err.message);
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error"|| err.response.status === 401) &&
      getbuttonCount < 5
    ) {
      getbuttonCount++;
      dispatch(refreshAuth("getbuttonslist", null, null, false));
    } else {
      getbuttonCount = 0;
      console.log("auth err 1: " + err);
      sendLogs("getbuttonsList Failed", err.message, "actions/button.js");
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};
export const originalgetbuttons = () => async (dispatch) => {
  console.log("hii12");
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  dispatch({ type: GET_VALIDATION_HEADER });
  dispatch({ type: CLEAR_BUTTON });
  dispatch({ type: EMPTY_BLANK_URL });
  var json = { apikey: ls.get("apikey") };
  try {
    const res = await axios.post(environment.btnstatus, json);
    console.log("originalgetbuttons " + res.data + res.data.error);
    sendLogs(
      "originalgetbuttons",
      "originalgetbuttons success",
      "actions/button.js"
    );
    dispatch({
      type: GET_BUTTONS,
      payload: res.data,
    });
  } catch (err) {
    //console.log(ls.get('refreshToken'));
    console.log("In catchhh " + err);
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      dispatch(refreshAuth("originalgetbuttons"));
    } else if (
      ls.get("refreshToken") === null &&
      err.message === "Network Error"
    ) {
      sendLogs("originalgetbuttons Failed", err.message, "actions/button.js");
      console.log("hii");
      dispatch({
        type: AUTH_ERRORS,
        payload: err.message,
      });
    } else {
      sendLogs("originalgetbuttons Failed", err.message, "actions/button.js");
      console.log("auth error 2 " + JSON.stringify(err));
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

export const apirefTrigger = () => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  dispatch({ type: GET_TRIGGER_HEADER });
  dispatch({ type: CLEAR_BUTTON });
  dispatch({ type: TRIGGER_COMPARE_VERIFICATION });
  var json = { apikey: ls.get("apikey") };
  try {
    const res = await axios.post(environment.btn_list, json);
    // console.log("apiref " +  JSON.stringify(res));
    if (res.data.error === true) {
      sendLogs("apiref Failed", res.data, "actions/button.js");
      if (res.data.status === 401) {
        if (ls.get("refreshToken")) {
          dispatch(refreshAuth("apiref"));
        } else {
          //console.log("auth err 7 : " + err);
          dispatch({
            type: AUTH_ERROR,
            // payload: err.response
          });
        }
      } else {
        sendLogs("apiref Failed", res.message, "actions/button.js");
        console.log("auth error 8 " + res.data.error);
        dispatch({
          type: AUTH_ERROR,
          payload: res.message,
        });
      }
    } else {
      sendLogs("apiref", "apiref success", "actions/button.js");
      dispatch({
        type: GET_BUTTONS,
        payload: res.data,
      });
    }
  } catch (err) {
    // console.log("err " + JSON.stringify(err )  + " " + err.message);
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      dispatch(refreshAuth("apiref"));
    } else if (
      ls.get("refreshToken") === null &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      console.log("hii");
      dispatch({
        type: AUTH_ERRORS,
        payload: err.message,
      });
    } else {
      console.log("auth err 9 : " + err);
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

export const apiref = () => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  dispatch({ type: GET_REFERENCE_HEADER });
  dispatch({ type: CLEAR_BUTTON });
  var json = { apikey: ls.get("apikey") };
  try {
    const res = await axios.post(environment.btn_list, json);
    // console.log("apiref " +  JSON.stringify(res));
    if (res.data.error === true) {
      sendLogs("apiref Failed", res.data, "actions/button.js");
      if (res.data.status === 401) {
        if (ls.get("refreshToken")) {
          dispatch(refreshAuth("apiref"));
        } else {
          //console.log("auth err 7 : " + err);
          dispatch({
            type: AUTH_ERROR,
            // payload: err.response
          });
        }
      } else {
        sendLogs("apiref Failed", res.message, "actions/button.js");
        console.log("auth error 8 " + res.data.error);
        dispatch({
          type: AUTH_ERROR,
          payload: res.message,
        });
      }
    } else {
      sendLogs("apiref", "apiref success", "actions/button.js");
      dispatch({
        type: GET_BUTTONS,
        payload: res.data,
      });
    }
  } catch (err) {
    // console.log("err " + JSON.stringify(err )  + " " + err.message);
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      dispatch(refreshAuth("apiref"));
    } else if (
      ls.get("refreshToken") === null &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      console.log("hii");
      dispatch({
        type: AUTH_ERRORS,
        payload: err.message,
      });
    } else {
      console.log("auth err 9 : " + err);
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

let getApiBtnCount = 0;
export const getapibutton = (buttonid) => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  // dispatch({ type: GET_REFERENCE_HEADER });
  // dispatch({ type: CLEAR_BUTTON });
  ////console.log(buttonid + "buttonid")
  let json;
  if (
    buttonid === "" ||
    buttonid === null ||
    buttonid === "null" ||
    buttonid === undefined
  ) {
    ////console.log("hii");
    json = { buttonid: ls.get("firstbtnid"), usertoken: guid() };
  } else {
    console.log("getapibutton");
    json = { buttonid: buttonid, usertoken: guid() };
  }
  try {
    const res = await axios.post(environment.getbtndata, json);
    sendLogs("getapibutton", "getapibutton success", "actions/button.js");
    // console.log("getapibutton " + res.data + res.data.error);
    if (res.data) {
      getApiBtnCount = 0;
    }
    dispatch({
      type: GET_API_BUTTON,
      payload: { buttonid: json.buttonid, payload: res.data },
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getApiBtnCount < 5
    ) {
      getApiBtnCount++;
      dispatch(refreshAuth("getapibutton", buttonid, null, false));
    } else {
      getApiBtnCount = 0;
      console.log("auth err : " + err);
      sendLogs("getapibutton Failed", err.message, "actions/button.js");
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

export const apiIntegration = () => async (dispatch) => {
  dispatch({ type: GET_INTEGRATION_HEADER });
};

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}
let buttonAddCount = 0;

export const buttonAdd = (data) => async (dispatch) => {
  const json1 = { apikey: ls.get("apikey"), buttonid: guid(), data: data };

  //console.log(json1);
  try {
    const res = await axios.post(environment.btn_data, json1);
    sendLogs("buttonAdd", "buttonAdd success", "actions/button.js");
    console.log("Add button " + JSON.stringify(res));
    if (res.data) {
      buttonAddCount = 0;
    }
    dispatch({
      type: ADD_BUTTON,
       payload: res.data.buttonid
    });
    dispatch(originalgetbuttons());
  } catch (err) {
    //console.log("err " + err + ls.get('refreshToken'));
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      buttonAddCount < 5
    ) {
      buttonAddCount++;
      dispatch(refreshAuth("buttonAdd", null, false));
    } else {
      buttonAddCount = 0;
      sendLogs("buttonAdd Failed", err.message, "actions/button.js");
      console.log("auth err 4: " + err);
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};



export const duplicateBtnAdd = (data) => async (dispatch) => {
  try {
    const res = await axios.post(environment.btn_data, data);
    sendLogs("buttonAdd", "buttonAdd success", "actions/button.js");
    console.log("Add button " + JSON.stringify(res));
    if (res.data) {
      buttonAddCount = 0;
    }

  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      buttonAddCount < 5
    ) {
      buttonAddCount++;
      dispatch(refreshAuth("buttonAdd", data, null, false));
    } else {
      buttonAddCount = 0;
      sendLogs("buttonAdd Failed", err.message, "actions/button.js");
      console.log("auth err 4: " + err);
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

// button Create Handler for sandbox to Production with add on data as in the buttonAdd method.
export const buttonAddSendboxToProduction = (data) => async (dispatch) => {
  
  try {
    console.log('here is the json data', JSON.stringify(data));
    const res = await axios.post(environment.btn_data, data);
    sendLogs("buttonAdd Sendbox To Production", "buttonAdd Sendbox To Production success", "actions/button.js");

    if(res.status === 200){
      const updatejson = res.data
      delete updatejson.data.coverage.note;
      const payload = {
        apikey: updatejson.data.apikey,
        buttonid:updatejson.data.buttonid,
        data:{...updatejson.data}
      };
      dispatch(updateButton(payload))
    }
    if (res.data) {
      buttonAddCount = 0;
    } 
    dispatch({      //dispatching the event of reducer.
      type: ADD_BUTTON,
       payload: res.data.buttonid
    });
    dispatch(originalgetbuttons());
  } catch (err) {
    //console.log("err " + err + ls.get('refreshToken'));
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      buttonAddCount < 5
    ) {
      buttonAddCount++;
      dispatch(refreshAuth("buttonAdd", data, null, false));
    } else {
      buttonAddCount = 0;
      sendLogs("buttonAddSendboxToProduction Failed", err.message, "actions/button.js");
      console.log("auth err 4: " + err);
      dispatch({     //dispatching the event of reducer.
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

// comparing the btn json's objects.
  export const deepCompareJson = (json1, json2) => {
    const excludedKeys = ['buttonid', 'apikey', 'sandboxid', 'eptime', 'crtime', 'org_info'];
   // Helper function to check if an argument is an object or array
    const isObjectOrArray = (arg) => {
      return typeof arg === 'object' && arg !== null;
    };
   // Helper function to get keys excluding the specified keys
    const getFilteredKeys = (obj) => {
      return Object.keys(obj).filter(key => !excludedKeys.includes(key));
    };
  
    const deepCompare = (a, b) => {
      if (isObjectOrArray(a) !== isObjectOrArray(b)) {
        return true; // Types are different, so they are different
      }
  
      if (!isObjectOrArray(a) && !isObjectOrArray(b)) {
        return a !== b; // Primitive values are different
      }
  
      const keysA = getFilteredKeys(a);
      const keysB = getFilteredKeys(b);
  
      if (keysA.length !== keysB.length) {
        return true; // Different number of keys after filtering
      }
  
      for (let key of keysA) {
        if (!(key in b)) {
          return true; // Key exists in `a` but not in `b`, so they are different
        }
        if (deepCompare(a[key], b[key])) {
          return true; // Recursively compare nested structures and values
        }
      }
  
      return false; // No differences found
    };
  
    return deepCompare(json1, json2);
  };
  



export const getButtonSettingHeader = (button_id) => async (dispatch) => {
  dispatch({
    type: GET_BUTTON_SETTING_HEADER,
    payload: button_id,
  });
};

let getBtnDtCount = 0;
export const getButtonData = (button_id) => async (dispatch) => {
  dispatch({ type: EMPTY_BLANK_URL });
  dispatch({ type: LOAD_BUTTON });
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  const json = { buttonid: button_id, usertoken: guid() };
  console.log("getButtonData");
  try {
    const res = await axios.post(environment.getbtndata, json);
    console.log("button " + JSON.stringify(res.data));
    sendLogs("getButtonData", "getButtonData success", "actions/button.js");
    if (res.data) {
      getBtnDtCount = 0;
    }
    dispatch({
      type: GET_BUTTON,
      payload: res.data,
    });
    return res.data.btndata;  //returning the api response of btn_data
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getBtnDtCount < 5
    ) {
      getBtnDtCount++;
      dispatch(refreshAuth("getButtonData", button_id, null, false));
    } else {
      getBtnDtCount = 0;
      console.log("auth err 5 : " + err);
      sendLogs("getButtonData Failed", err.message, "actions/button.js");
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

let getMstFldCount = 0;
export const getmasterfieldsdata = () => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  try {
    const res = await axios.get(environment.getmasterfields);
    console.log("getmasterfieldsdata" + JSON.stringify(res));
    sendLogs(
      "getmasterfieldsdata",
      "getmasterfieldsdata success",
      "actions/button.js"
    );
    if (res.data) {
      getMstFldCount = 0;
    }
    dispatch({
      type: GET_MASTERFIELDDATA,
      payload: res.data,
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getMstFldCount < 5
    ) {
      getMstFldCount++;
      dispatch(refreshAuth("getmasterfieldsdata", null, null, false));
    } else {
      getMstFldCount = 0;
      console.log("auth err 6 : " + err);
      sendLogs("getmasterfieldsdata Failed", err.message, "actions/button.js");
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};

export const getViewDoc = (button_id) => async (dispatch) => {
  dispatch({
    type: GET_VIEW_DOC_HEADER,
    payload: button_id,
  });
  //getapibutton(button_id);
};

let updBtnCount = 0;
export const updateButton = (data) => async (dispatch) => {
  axios.defaults.headers.common["Authorization"] = ls.get("token");
  //dispatch({type:ADD_BUTTON});
  try {
    const res = await axios.post(environment.update_btn, data);
    sendLogs("updateButton", "updateButton success", "actions/button.js");
    console.log("button response ============================", res.data);
    if (res.data.data.error) {
    } 
    else {
      updBtnCount = 0;
      await dispatch({
        type: UPDATE_BUTTON,
        payload: res.data,
      });
      setTimeout(() => {
        window.location.reload();
    }, 1000);
    }
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      updBtnCount < 5
    ) {
      updBtnCount++;
      dispatch(refreshAuth("updateButton", data, null, false));
    } else {
      updBtnCount = 0;
      sendLogs("updateButton Failed", err.message, "actions/button.js");
      console.log("auth err 3 : " + err);
      dispatch({
        type: AUTH_ERROR,
        payload: err.message,
      });
    }
  }
};
export const testEmailReminder = (data) => async (dispatch) => {
  try {
    console.log("data", data);
    const res = await axios.post(environment.testEmailReminder, data);
    sendLogs("testEmailReminder", res);

    dispatch({
      type: TEST_EMAILREMINDER,
      payload: res.data,
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error")
    ) {
      dispatch(refreshAuth("testEmailReminder", data));
    } else {
      sendLogs("test email reminder Failed", err.message, "actions/button.js");
      console.log("auth err 5 : " + err);
      dispatch({
        type: TEST_EMAILREMINDER,
        payload: err.message,
      });
    }
  }
};

let delBtnCount = 0;
export const deleteButton = (data, history) => async(dispatch) => {
  axios
    .post(environment.deletebutton, data)
    .then((res) => {
      delBtnCount = 0;
      dispatch({
        type: BUTTON_DELETE,
        payload: {
          num: 1,
          title: "Button deleted",
          msg: "Button deleted successfully",
        },
      });
      history.push("/client/validation-buttons");
      setTimeout(async() => {
        await dispatch({ type: BUTTON_DEL_SUCCESS });
        await dispatch(originalgetbuttons())
      }, 1500);
      
    })
    .catch((err) => {
      if (
        ls.get("refreshToken") &&
        (err.message === "Request failed with status code 401" ||
          err.message === "Network Error") &&
        delBtnCount < 5
      ) {
        delBtnCount++;
        dispatch(refreshAuth("deleteButton", data));
      } else {
        delBtnCount = 0;
        console.log("auth err 4 : " + err);
        sendLogs("deleteButton Failed", err.message, "actions/button.js");
        dispatch({
          type: AUTH_ERROR,
          payload: err.message,
        });
      }
    });
};

// export const deleteButton = (data,history) =>  dispatch => {

//     axios
//     .post(environment.deletebutton,data);
//     //console.log("Add button " + JSON.stringify(res));
//     // dispatch({
//     //   type: DELETE_BUTTON,
//     //   payload: res.data
//     // });
//     .then(res => history.push('/client/admin-dash'));
//     //dispatch(originalgetbuttons());
//   // }
//   .catch (err => {
//     if(ls.get('refreshToken'))
//     {
//       dispatch(refreshAuth('deleteButton',data));
//     }
//     else
//     {
//     dispatch({
//       type: AUTH_ERROR,
//       payload: err.response
//       });
//     }
//   });
// };

export const removeAlert = () => async (dispatch) => {
  dispatch({
    type: REMOVE_BUTTON_ALERT,
  });
  dispatch(originalgetbuttons());
};

export const changeUrltiApiIntegration =
  (btn_id, history) => async (dispatch) => {
    ls.set("firstbtnid", btn_id);
    // <Redirect to={'/client/developers/api-refrence'}/>;
    // const history = useHistory();
    // history.push('/client/developers/api-refrence');
  };

export const removeSweatAlert = (history) => (dispatch) => {
  dispatch({ type: REMOVE_AUTH_ERROR });
  // dispatch({ type: AUTH_ERRORS });
  //history.push('/client/validation-buttons')
};
export const getemailreminderdata = () => async (dispatch) => {
  try {
    const res = await axios.get(environment.getemailreminder);
    // console.log("btn_list==>" + res.data);
    sendLogs("getemailreminder");
    dispatch({
      type: GET_EMAILREMINDER_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log("auth err 1: " + err);
    sendLogs("getemailreminder", err.message, "actions/button.js");
    dispatch({
      type: AUTH_ERROR,
      payload: err.message,
    });
  }
};

// Get Country List
let getCountry = 0;
export const getCountryData = () => async (dispatch) => {
  dispatch({ type: LOAD_COUNTRY_LIST });
  try {
    const res = await axios.get(environment.verifiedcountrylist);
    console.log(res);
    sendLogs("getCountry", "getCountry success", "actions/table.js");
    if (res.data) {
      getCountry = 0;
    }
    dispatch({
      type: GET_COUNTRY_LIST,
      payload: res.data.data,
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getCountry < 5
    ) {
      getCountry++;
      dispatch(refreshAuth("getCountryData", null, null, false));
    } else {
      console.log(err);
      sendLogs("getCountry Failed", err.message, "actions/table.js");
      dispatch({
        type: ERR_COUNTRY_LIST,
        payload: err.response,
      });
    }
  }
};
let getCountryLink = 0;

export const getCountryLinkData = (data) => async (dispatch) => {
  let jsonObj;
  if (data.searching) {
    jsonObj = {
      category: data.cat,
      country: data.key,
      index: 0,
      offset: 100,
      search: data.search,
    };
  } else {
    dispatch({ type: LOAD_COUNTRY_LINKS });
    jsonObj = {
      category: data.cat,
      country: data.key,
      index: 0,
      offset: 100,
      search: "",
    };
  }
  try {
    const res = await axios.post(environment.fulltextsearch, jsonObj);
    console.log(
      "getCountryLinkData Response !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
      res
    );
    sendLogs("getCountry", "getCountry success", "actions/table.js");
    if (res.status === 200 || res.status === 204) {
      getCountryLink = 0;
    }
    dispatch({
      type: GET_COUNTRY_LINKS,
      payload: {
        res: res.status === 200 ? res.data.data : [],
        cat: data.cat,
        searching: data.searching ? data.searching : false,
      },
    });
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      getCountryLink < 5
    ) {
      getCountryLink++;
      dispatch(refreshAuth("getCountryLink", data, null, false));
    } else {
      console.log(err);
      sendLogs("getCountryLink Failed", err.message, "actions/table.js");
      dispatch({
        type: ERR_COUNTRY_LINKS,
        payload: err.response,
      });
    }
  }
};
let deletedata = 0;
export const deleteTableData = (data) =>  async (dispatch) => {
    var json = {
      category:data.category,
      country: data.country,
      countryid: data.uniquekey,
      id: data.id,
      link: data.link,
      nickname: "",
      subcategory: "",
      submit: {
        country: data.country,
        countryid: data.uniquekey,
        mobile: ls.get("email"),
        apikey: ls.get("apikey"),
      },
    };
    console.log(json);

    try {
      const response = await axios.post(environment.deletesource, json);
      console.log(response);
      sendLogs("deletetabledata", "deletetabledata success", "actions/table.js");
      if (response.status === 200 || response.status === 204) {
        deletedata = 0;
      }
     
      dispatch({ type: DELETE_TABLE_DATA_SUCCESS });

      // Dispatch success action if deletion is successful
    } 
      catch (err) {
        if (
          ls.get("refreshToken") &&
          (err.message === "Request failed with status code 401" ||
            err.message === "Network Error") &&
            deletedata < 5
        ) {
          deletedata++
          dispatch(refreshAuth("deleteTableData", data, null, false));
        } else {
          console.log(err);
          sendLogs("deleteTableData Failed", err.message, "actions/table.js");
          
        }
      }
     // dispatch({ type: DELETE_TABLE_DATA_SUCCESS, payload: true });
      // Dispatch failure action if deletion fails
    
  };
  let updateData = 0;

  export const updateTableData = (data) =>  async (dispatch) => {
    var json = {
      category:data.category,
      country: data.country,
      countryid: data.uniquekey,
      id: data.id,
      link: data.link,
      nickname: data.nickname,
      subcategory: data.subcategory,
      submit: {
        country: data.country,
        countryid: data.uniquekey,
        mobile: ls.get("email"),
        apikey: ls.get("apikey"),
      },
      type:"link"
    };
    console.log(json);

    try {
      const response = await axios.post(environment.updatesource, json);
      console.log(response);
      sendLogs("updatetabledata", "updatetabledata success", "actions/table.js");
      if (response.status === 200 || response.status === 204) {
        updateData = 0;
      }
      dispatch({ type: UPDATE_TABLE_DATA});

    }
      catch (err) {
        if (
          ls.get("refreshToken") &&
          (err.message === "Request failed with status code 401" ||
            err.message === "Network Error")  && updateData < 5
        ) {
          updateData++
          dispatch(refreshAuth("updateTableData", data, null, false));
        } else {
          console.log(err);
          sendLogs("updateTableData Failed", err.message, "actions/table.js");
          
        }
      }
     

  };

  //==================action generateSecretToken 
  export const generateSecretToken = (token, orgId, apikey) => async (dispatch) => {
  // Set the Authorization header
  axios.defaults.headers.common["Authorization"] = ls.get("token");

  const sandbox = apikey.startsWith('d-');
  // const cleanedToken = sandbox ? token : token.startsWith('Bearer ') ? token.slice(7) : token;

  let cleanedToken;
  
    if (sandbox) {
       cleanedToken = token;
     } else if (token.startsWith('Bearer ')) {
       cleanedToken = token.slice(7);
     } else {
    cleanedToken = token;
    }

  const json = {
    accesstoken: cleanedToken,
    orgid: orgId,
    sandbox: sandbox
  };

  console.log("Payload to generate secret token:", json); // Log payload

  try {
    console.log("api called")
    
    const response = await axios.post(environment.generateSecretToken, json);
    console.log("api called and " ,response.status)

    if (response.status === 200) {
      console.log("Response from API:", response.data);
      if (sandbox) {
        ls.set("secrettoken", response.data);
        ls.set("tokenTest", response.data)
        console.log("Response sandbox", response.data);
      } else {
        ls.set("secrettoken", "Bearer " + response.data);
        ls.set("tempsecret","Bearer " + response.data)
        console.log("Response out of sandbox", response.data);
      }
      return response.data;
    } else {
      console.log('Something went wrong', response.status, response.data);
      return null;
    }

  } catch (error) {
    console.log("inside the err block")
    // console.log("inside the err block1",error.response) //undefined
    console.log("inside the err block1",error.message)
    if(error.message === "Request failed with status code 401" || error.message === "Network Error"){
      //handling when token is expire
      dispatch(refreshAuth("generateSecretToken", token, orgId, false, apikey));
      }else if (error.response) {
      if (error.response.status === 400) {
        console.log("Invalid token to generate new secret token:", error.response.data);
      } 
      if(error.message === "Request failed with status code 401" || error.message === "Network Error"){
              //handling when token is expire
              dispatch(refreshAuth("generateSecretToken", token, orgId, false, apikey));
              }
       else {
        console.log("Unexpected error occurred:", error.response.data);
      }
    } else if (error.request) { 
      // The request was made but no response was received
      console.log("Error request:", error.request);
    } 
  }
};


  let createdata = 0;
  export const createSource = (data) =>  async (dispatch) => {
    var json = {
      category:data.category,
      country: data.country,
      countryid: data.uniquekey,
      id: data.id,
      link: data.link,
      nickname: data.nickname,
      subcategory: data.subcategory,
      submit: {
        apikey: ls.get("apikey"),
        country: data.country,
        countryid: data.uniquekey,
        mobile: ls.get("email"),
        
      },
      type:"link"
    };
    console.log(json);

    try {
      const response = await axios.post(environment.createsource, json);
      console.log(response);
      sendLogs("updatetabledata", "updatetabledata success", "actions/table.js");
      if (response.status === 200 || response.status === 204) {
        createdata = 0;
      }
      dispatch({ type: CREATE_SOURCE });


    }  catch (err) {
      if (
        ls.get("refreshToken") &&
        (err.message === "Request failed with status code 401" ||
          err.message === "Network Error")  && createdata < 5
      ) {
        createdata++
        dispatch(refreshAuth("createTableData", data, null, false));
      } else {
        console.log(err);
        sendLogs("createTableData Failed", err.message, "actions/table.js");
        
      }
    }
  };

export const resetCountry = () => async (dispatch) => {
  dispatch({ type: RESET_COUNTRY });
};
export const resetDirectLink = () => async (dispatch) => {
  dispatch({ type: RESET_DIRECT_LINK });
};
export const setAlpha2code = (data) => async (dispatch) => {
  dispatch({ type: SET_ALPHA_CODE, payload: data });
};
export const setCategory = (data) => async(dispatch) => {
  await dispatch({ type: SET_CATEGORY, payload: data });
};
