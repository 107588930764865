import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Row,
	Col,
	Card,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	FormGroup,
	Input,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	DropdownItem,
	Alert,
	Label,
	Form,
	ModalHeader,
	Table,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import SweetAlert from "react-bootstrap-sweetalert";
import { sessionReport, submitFeedbackData, removeFeedbackAlert, deleteSessionId, getpdftojson ,
	getExtractTransactionData 
} from "../../actions/table";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SessionReportTable from "../Requests-sent/SessionReportTable.js";
import Spinner from "../spinner/Spinner";
import { getLastClickedDocument } from "../../actions/viewdoc";
import { parseDomain } from "parse-domain";
import ReactJson from "react-json-view";
import ls from "localstorage-slim";
import downloadIcon from "../../assets/images/file.png";
import captureIcon from "../../assets/images/screenshot.png";

// ====================== add here upload icon 
import uploadicon from "../../assets/images/upoladIcon.png";


ls.config.encrypt = true;
const RejectTable = (
	{
		rejects,
		getLastClickedDocument,
		viewdoc: { viewdata },
		deleteSessionId,
		submitFeedbackData,
		removeFeedbackAlert,
		submitFeedalert,
		getpdftojson,
		sessionReport,
		className,
		getExtractTransactionData ,
		auth: { user },
		table: { limitrejets, session_report, loading, pdfjson, pdf_loading, pdfjsonprocessing },
		btnSettingDelBtn,
	},
	props
) => {
	//const {  id } = props;
	//const [tooltipOpen, setTooltipOpen] = useState(false);
	console.log("session_report_rejected", session_report);

	const [modal, setModal] = useState();
	const [modalData, getData] = useState({ alldata: "" });
	const [copied, setCopyModal] = useState(false);
	const [activeTab, setActiveTab] = useState("2");
	const [jsonData, setjsonData] = useState();
	const [sesId, setsesid] = useState("");
	const [reportModal, setReportModal] = useState(false);
	const [pdftojsonmodal, setPdftojsonModal] = useState(false);
	const [copiedpdf, setCopypdfModal] = useState(false);
	const [loader, setLoader] = useState(true);
	const [delSession, setDelSession] = useState(false);

	const [sessionModal, setsessionModal] = useState(false);
	const [formData, setFormData] = useState({
		emailId: ls.get("email"),
		//session_id: sesId,
		comment: "",
		source: "client portal",
	});
	const [badDocAlert, setBadDocAlert] = useState(false);
	//const [sesionbtnId, setsesionbtnId] = useState('');
	const [isOpen1, setIsOpen1] = useState(false);
	const [err, seterror] = useState({
		comment: "",
	});

	const transactiondata = useSelector((state)=> state.table.transactionData) 
	
	const [showTransactionDataTab, setShowTransactionDataTab] = useState(false);

	const [did, setdid] = useState("");
	//const [charErr , setCharError] = useState(false);
	let pdfjsondata = {};
	pdfjsondata.message = pdfjson.message;
	pdfjsondata.data = pdfjson.data;
	pdfjsondata.status = pdfjson.status;
	pdfjsondata.statusText = pdfjson.statusText;

	//  useEffect(()=>{console.log("ineffect");
	// if(pdfjsonprocessing.status){
	//    console.log("inprocess")
	//    setTimeout(() => {
	//       getpdftojson(did)
	//    }, 7000);
	// }
	// },[pdfjsonprocessing])
	useEffect(() => {
		console.log("ineffect");
		let pdfprocessing = pdfjsonprocessing.status;
		setLoader(true);

		if (pdfprocessing) {
			if (!pdfprocessing.includes("Processing result")) {
				console.log("inprocess");
				setTimeout(() => {
					getpdftojson(did);
				}, 45000);
			}
		}
	}, [pdfjsonprocessing, did, getpdftojson]);

	useEffect(() => {
		console.log("Set Sweet Alert", btnSettingDelBtn);
		if(btnSettingDelBtn.reset) {
			setSweetAlert({
				Alertdanger: false,
			});
			setDelSession(false);
		}
	}, [btnSettingDelBtn]);

	const togglebtn = (e, id) => {
		console.log(e);
		console.log(id);
		setIsOpen1(!isOpen1);
		// setsesionbtnId(e)
	};
	const [dangerAlert, setSweetAlert] = useState({
		Alertdanger: false,
		deletedsession: "",
	});
	const [docAlert, setDocAlert] = useState({
		Alertdanger: false,
	});
	const onDismissDocView = () => {
		setDocAlert({
			Alertdanger: false,
		});
	};
	const onOpenDocView = (x) => {
		if (x.file.reasonforbaddoc) setBadDocAlert(true);
		else {
			setDocAlert({
				Alertdanger: true,
			});
		}
	};
	const { emailId, comment } = formData;

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });




	
	const [copiedsessionid, setCopiedsessionid] = useState(false);


	const handleCopyDocID =(docid)=>{
	  navigator.clipboard.writeText(docid)
  
	 .then(() => {
	  setCopiedsessionid(prevState => ({
					  ...prevState,
					  [docid]: true
				  }));
				//   setTimeout(() => {
				// 	setCopiedsessionid(prevState => ({
				// 		  ...prevState,
				// 		  [docid]: false
				// 	  }));
				//   }, 3000); 
		   setsessionModal(modal);
			  })
	.catch(err => {
		console.error('Failed to copy: ', err);
		// alert("Failed to copy DocID!");
	});
  
	}
	
	const copyclipboard = () => {
		//console.log("hello");
		setCopyModal(true);
	};

	const closeReportModal = () => {
		setReportModal(false);
		seterror({comment: ""})	
	};

	const onDismiss = () => {
		removeFeedbackAlert();
		setCopyModal(false);
		setCopypdfModal(false);
	};
	const toggle = (x) => {
		//console.log(x)
		if (x) {
			getData({ alldata: x });
			setModal(!modal);
		}
	};

	const sessiontoggle = (e, index, token) => {
		// console.log("hii");
		if (index) {
			getLastClickedDocument(index);
			sessionReport(index);
			setsesid(index)
		}
		setsessionModal(!modal);
	};
	const deleteSession = (x) => {
		console.log(dangerAlert.Alertdanger);
		console.log("on click" + limitrejets);
		deleteSessionId(x, "Rejected", limitrejets);
		setDelSession(true);
		// setSweetAlert({
		// 	Alertdanger: false,
		// });
	};

	const deleteToggle = (x) => {
		console.log(x);
		// eslint-disable-next-line
		setSweetAlert({
			Alertdanger: true,
			deletedsession: x,
		});
	};

	const onDismissdeletesession = () => {
		if(!delSession) {
			setSweetAlert({
				Alertdanger: false,
			});
		}
	};
	const sessionToggleClose = (e, index, token) => {
		setsessionModal(!sessionModal);
	};

	const copyclipboardPdf = () => {
		//console.log("hello");
		setCopypdfModal(true);
	};

	const onReportSubmit = (e) => {
		e.preventDefault();
		if (handleValidation()) {
			const FeedData = {
				email: ls.get("email"),
				sessionId: sesId,
				comment: formData.comment,
				source: "client portal",
			};

			submitFeedbackData(FeedData, true);
			console.log(FeedData);
			setFormData({ ...formData, comment: "" });
			// setReportModal(false);
			setTimeout(() => {
				submitFeedbackData(FeedData, false);
			  }, 3000); // Hide success message after 3 seconds
		
		}
	};

	const openPdftojsonModal = (x, data) => {
		if (data?.file?.reasonforbaddoc) {
			setBadDocAlert(true);
			return;
		}
		console.log(x);
		setTimeout(() => {
			setLoader(false);
		}, 3000);
		setdid(x);
		// getpdftojson(x);
		setPdftojsonModal(true);
		setjsonData(data);
		// code added

		getpdftojson(x);

		getExtractTransactionData(x);

		// if (data.file.category === "bank") {
		//   getExtractTransactionData(x);
		// }
	   // Conditionally call getExtractTransactionData based on category
	   if (data.file?.category === "bank") {
		getExtractTransactionData(x);
		setShowTransactionDataTab(true); // Show the NavItem
	  } else {
		setShowTransactionDataTab(false); // Hide the NavItem
	};
	};

	const closePdftojsonModal = (x) => {
		console.log(x);
		setPdftojsonModal(false);
		setCopypdfModal(false);
	};
	const onDismissBadDocView = () => {
		setBadDocAlert(false);
	};
	const handleValidation = () => {
		//console.log("handle validation")
		let fields = formData;
		let errors = {};
		let formIsValid = true;

		//first Name

		if (fields["comment"].length === 0) {
			formIsValid = false;
			// setCharError(true);
			errors["comment"] = "This field is required";
		}

		//console.log("err " +  errors.firstName);
		seterror({
			comment: errors.comment,
		});
		//console.log(err.errors);
		return formIsValid;
	};

	const toggle1 = () => {
		// console.log(x)
		getData({ alldata: "" });
		setModal(!modal);
	};
	const getFullUrl = (url) => {
		// run against regex
		// const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
		// const matches = url?.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
		const matches = url?.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
		// http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?
		// extract hostname (will be null if no match is found)
		return matches && matches[1];
	};

	const getHostnameFromRegex = (url) => {
		// run against regex
		const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
		// extract hostname (will be null if no match is found)

		// Get domain
		const parseResult = parseDomain(
			// This should be a string with basic latin letters only.
			matches && matches[1]
		);
		console.log("INSIDE domainfilter");
		// Check if the domain is listed in the public suffix list
		// if (parseResult.type === ParseResultType.Listed) {
		const { domain, topLevelDomains } = parseResult;
		var ddomain;
		if (domain && topLevelDomains) {
			ddomain = `${domain}.${topLevelDomains[0] ? topLevelDomains[0] : topLevelDomains}${topLevelDomains[1] ? "." + topLevelDomains[1] : ""}`;
		}
		// console.log("subdomain:>", subDomains); // ["www", "some"]
		// console.log("domain:>", domain); // "example"
		// console.log("topleveldomain:>", topLevelDomains); // ["co", "uk"]
		// }
		if (domain) {
			return ddomain;
		} else return matches && matches[1];
	};

	const openReportModal = (x) => {
		console.log(x);
		setsesid(x);
		setReportModal(true);
	};

	const makefileurl = (file) => {
		//console.log(file);
		// const fileurl = window.btoa(file) ;
		// const dec = window.atob(fileurl);
		ls.remove("fileurl");
		ls.set("fileurl", file);
		//console.log(file);
		// console.log(dec);
		return file;
	};

	const getUserTime = (eptime) => {
		var dateMDY = "";
		if (eptime === null || eptime === "" || eptime === undefined) {
			return dateMDY;
		}
		var date = new Date();
		var d = date.getTime();
		var diff = d - eptime;
		var minD;
		var hrd;
		var dayd;
		var weekd;
		var mond;
		switch (true) {
			case 0 <= diff && diff < 60000:
				diff = diff / 1000;
				diff = diff.toFixed(0);
				dateMDY = diff + " Secs ago";
				break;
			case 60000 <= diff && diff < 60000 * 2:
				minD = 60000;
				diff = diff / minD;
				diff = diff.toFixed(0);
				dateMDY = diff + " Min ago";
				break;
			case 60000 * 2 <= diff && diff < 60000 * 60:
				minD = 60000;
				diff = diff / minD;
				diff = diff.toFixed(0);
				dateMDY = diff + " Mins ago";
				break;
			case 60000 * 60 <= diff && diff < 60000 * 60 * 2:
				hrd = 60000 * 60;
				diff = diff / hrd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Hour ago";
				break;
			case 60000 * 60 * 2 <= diff && diff < 60000 * 60 * 24:
				hrd = 60000 * 60;
				diff = diff / hrd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Hours ago";
				break;
			case 60000 * 60 * 24 <= diff && diff < 60000 * 60 * 24 * 2:
				dayd = 60000 * 60 * 24;
				diff = diff / dayd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Day ago";
				break;
			case 60000 * 60 * 24 * 2 <= diff && diff < 60000 * 60 * 24 * 7:
				dayd = 60000 * 60 * 24;
				diff = diff / dayd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Days ago";
				break;
			case 60000 * 60 * 24 * 7 <= diff && diff < 60000 * 60 * 24 * 7 * 1.5:
				weekd = 60000 * 60 * 24 * 7;
				diff = diff / weekd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Week ago";
				break;
			case 60000 * 60 * 24 * 7 * 1.5 <= diff && diff < 60000 * 60 * 24 * 7 * 4:
				weekd = 60000 * 60 * 24 * 7;
				diff = diff / weekd;
				diff = diff.toFixed(0);
				dateMDY = diff + " Weeks ago";
				break;
			case 60000 * 60 * 24 * 7 * 4 <= diff && diff < 60000 * 60 * 24 * 7 * 4 * 1.5:
				mond = 60000 * 60 * 7 * 4 * 24;
				diff = diff / mond;
				diff = diff.toFixed(0);
				dateMDY = diff + " Month ago";
				break;
			case 60000 * 60 * 24 * 7 * 4 * 1.5 <= diff && diff < 60000 * 60 * 24 * 7 * 4 * 3:
				mond = 60000 * 60 * 7 * 4 * 24;
				diff = diff / mond;
				diff = diff.toFixed(0);
				dateMDY = diff + " Months ago";
				break;
			default:
				var fileDate = new Date(eptime);
				var fileD = fileDate.getDate();
				var fileM = fileDate.getMonth();
				var fileY = fileDate.getFullYear();
				dateMDY = fileD + "-" + fileM + "-" + fileY;
		}
		// console.log(dateMDY);
		if (dateMDY === "NaN-NaN-NaN") {
			dateMDY = "";
			return dateMDY;
		} else {
			return dateMDY;
		}
	};
	// const Org = (orgId, Allorgdetail) => {
	// 	for (let i = 0; i < Allorgdetail.length; i++) {
	// 		if (orgId === Allorgdetail[i].orgid) {
	// 			return Allorgdetail[i].orgname;
	// 		}
	// 	}
	// };

	const firstLetterCap = (string) => {
		if (string) {
			const str = string;
			const str2 = str.charAt(0).toUpperCase() + str.slice(1);
			// console.log(str2);
			return str2;
		} else {
			return "Other";
		}
	};

	const truncate = (str) => {
		return str.length > 25 ? str.substring(0, 23) + "..." : str;
	};
	const pdfGenerated = (file) => {
		let value;
		for (let key in file) {
			value = file[key];
		}
		if (value?.type === "final-pdftojson") {
			return false;
		} else {
			return true;
		}
	};
	const pdfDataType = (file) => {
		let value;
		for (let key in file) {
			value = file[key];
		}
		if (value?.type) {
			let typeofData = "(" + value?.type.split("-")[0] + " response)";
			return typeofData;
		}
	};
	const fromCell = (x) => {
		return (
			<div style={{ verticalAlign: "middle", display: "flex", justifyContent: "left" }}>
				{/* <div>
					<i
						className="fa fa-circle mr-1 text-danger"
						style={{ verticalAlign: "middle", paddingLeft: "5px", paddingBottom: "3px", marginTop: "-4px" }}
						data-tip
						data-for="rejected"
					/>
				</div>
				<ReactTooltip id="rejected" place="top" effect="solid">
					Rejected
				</ReactTooltip> */}
				{/* <Tooltip placement={x.placement} isOpen={tooltipOpen} target={"Tooltip-" + id} toggle={toggle2} >
					Rejected
				</Tooltip> */}
				<div>
					<div
						style={{
							textAlign: "left",
							display: "flex",
							justifyContent: "left",
							fontWeight: "400",
							fontSize: "14px",
							color: "black",
						
						}}
					>
						{x.button.name}
						<br />
					</div>
					{/* <div  style={{display: "flex", justifyContent: "left", fontSize: "11px" }}>{x.mxdoc.mobile}</div> */}
				</div>
			</div>
		);
	};
	const verificationCell = (x) => {
		return (
			<span style={{ verticalAlign: "middle", display: "flex", justifyContent: "left" }}>
				{x.file.urlStatus ? (
					<React.Fragment>
					<span>
					  <i
					 className = {
						//added the checks for the logos according to the doucment type and the fraudcheck score
						x.button.mode.type === "upload"
						? x.fraudCheck === null || x.fraudCheck?.verifiedScore === null
						  ? ""
						  : x.fraudCheck?.verifiedScore === 0
							? "fa fa-times mr-1 text-danger"
							: x.fraudCheck?.verifiedScore === 50
							  ? "image-for-50 mr-1 text-warning"
							  : x.fraudCheck?.verifiedScore === 75
								? "fa fa-check mr-1 text-success"
								: x.fraudCheck?.verifiedScore === 100
								  ? "image-for-100 mr-1 text-success"
								  : ""
						: x.file.urlStatus.urlMatch && x.file.urlStatus.sslVerified
						  ? "fa fa-check-circle mr-1 text-success"
						  : !x.file.urlStatus.sslVerified
							? "fa fa-close mr-1 text-danger"
							: "fa fa-question mr-1 text-warning"
					}
					style={{
					  verticalAlign: "middle",
					  marginRight: "0px"
					}}
					data-tip
					data-for={
						//show the tool tip according to the document type and fraudcheck score.
						x.button.mode.type === "upload"
						  ? x.fraudCheck === null || x.fraudCheck?.verifiedScore === null
							? "danger upload"
							: x.fraudCheck?.verifiedScore === 0
							  ? "danger upload"
							  : x.fraudCheck?.verifiedScore === 50
								? "warning upload"
								: x.fraudCheck?.verifiedScore === 75
								  ? "info upload"
								  : x.fraudCheck?.verifiedScore === 100
									? "success upload"
									: ""
						  : x.file.urlStatus.urlMatch && x.file.urlStatus.sslVerified
							? "success"
							: !x.file.urlStatus.sslVerified
							  ? "danger"
							  : "warning"
					  }   
					  />
					</span>
		
					{x.button.mode.type === 'upload' ? (
						<>
						{x.fraudCheck?.verifiedScore === 0 &&  (
							<ReactTooltip id="danger upload" place="top" effect="solid">
							Tampered (score 0%)
							</ReactTooltip>
						)}
						{x.fraudCheck?.verifiedScore === 50 && (
							<ReactTooltip id="warning upload" place="top" effect="solid">
							Suspicious (score 50%)
							</ReactTooltip>
						)}
						{x.fraudCheck?.verifiedScore === 75 && (
							<ReactTooltip id="info upload" place="top" effect="solid">
							Likely pass (score 75%)
							</ReactTooltip>
						)}
						{x.fraudCheck?.verifiedScore === 100 && x?.button?.mode?.type === 'upload' && (
							<ReactTooltip id="success upload" place="top" effect="solid">
							Pass (score 100%)
							</ReactTooltip>
						)}
						</>
					) : (
						<>
						{x.file.urlStatus.urlMatch && x.file.urlStatus.sslVerified && x.button.mode.type !=='upload' ? (
							<ReactTooltip id="success" place="top" effect="solid">
							Source verified
							</ReactTooltip>
						) : !x.file.urlStatus.sslVerified ? (
							<ReactTooltip id="danger" place="top" effect="solid">
							Source not verified
							</ReactTooltip>
						) : (
							<ReactTooltip id="warning" place="top" effect="solid">
							Verify again
							</ReactTooltip>
						)}
						</>
					)}
				</React.Fragment>
		
				) : (
					""
				)}

				<div style={{ display: "flex", justifyContent: "left", fontWeight: "400", fontSize: "14px", color: "black" }} data-tip data-for={x.file.url}>
					{getHostnameFromRegex(x.file.url)}
				</div>
				<ReactTooltip id={x.file.url} place="top" effect="solid">
					{getFullUrl(x.file.url)}
				</ReactTooltip>
			</span>
		);
	};
	rejects.map((x) => {
		let hashKeyMht = x.file?.mhtmlhashkey;
		x.mhtmlVal = "";
		for (const key in hashKeyMht) {
			// console.log(`${key}: ${user[key]}`);
			if ((key + "").slice(-4) === ".mht") {
				x.mhtmlVal = "png";
			}
		}
		return null;
	});
	let rew_table;
	rew_table = rejects.map((x, index) => (
		<tr className="table-tr" key={index}>
			<td style={{ verticalAlign: "middle", padding: "10px", paddingBlock: "11px", lineHeight: "1" }}>
				{/* {x?.button?.shareonlyjson &&
				pdfGenerated(x.file.pdfdata) &&
				// x?.button?.mode?.type !== "capture" &&
				user?.email !== "dirolabs@gmail.com" &&
				x?.file?.json_v3_status !== "final-pdftojson" ? (
					<div className="cursor-pointer" onClick={onOpenDocView.bind(null, x)}  onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
						  onOpenDocView(x);
						}
					  }}
					  tabIndex={0} 
					  role="button">
						{fromCell(x)}
					</div>
				) : ( */}
					<Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
						{fromCell(x)}
					</Link>
				{/* // )} */}
			</td>
			<td style={{ verticalAlign: "middle", padding: "10px", paddingBlock: "11px", lineHeight: "1" }}>
				{x?.button?.shareonlyjson &&
				pdfGenerated(x.file.pdfdata) &&
				// x?.button?.mode?.type !== "capture" &&
				user?.email !== "dirolabs@gmail.com" &&
				x?.file?.json_v3_status !== "final-pdftojson" ? (
					<div className="cursor-pointer" onClick={onOpenDocView.bind(null, x)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
						  onOpenDocView(x);
						}
					  }}
					  role="button"
					  tabIndex={0}>
						<div style={{ display: "flex", justifyContent: "left", alignItems: "center", fontWeight: "400", fontSize: "14px", color: "black" }}>
							{/* === check if the file extension is .png i.e., capture  ===*/}
							{/*  ===== Vinay's Code =======
							{x.file.mhtmlhashkey && Object.keys(x.file?.mhtmlhashkey)[1]?.slice(-4) === ".png" ? (
								<img className="type-icon" src={captureIcon} alt="capture" data-tip data-for="capture-button" />
							) : (
								<img className="type-icon" src={downloadIcon} alt="download" data-tip data-for="download-button" />
							)} */}
							{x.mhtmlVal === "png" ? (
								<img className="type-icon" src={captureIcon} alt="capture" data-tip data-for="capture-button" />
							)  : x.button.mode.type === "upload" ? (
								<img
								  className="type-icon"
								  src={uploadicon}
								  alt="upload"
								  data-tip
								  data-for="upload-button"
								/>
							  ): (
								<img className="type-icon" src={downloadIcon} alt="download" data-tip data-for="download-button" />
							)}
							<ReactTooltip id="download-button" place="top" effect="solid">
								Download
							</ReactTooltip>
							<ReactTooltip id="capture-button" place="top" effect="solid">
								Screenshot
							</ReactTooltip>
							<ReactTooltip id="upload-button" place="top" effect="solid">
                                Upload
                            </ReactTooltip>
							{firstLetterCap(x.file.category)}
							<br />
						</div>
					</div>
				) : (
					<Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
						<div style={{ display: "flex", justifyContent: "left", alignItems: "center", fontWeight: "400", fontSize: "14px", color: "black" }}>
							{/* === check if the file extension is .png i.e., capture  ===*/}
							{/* ===== Vinay's Code =======
							{x.file.mhtmlhashkey && Object.keys(x.file?.mhtmlhashkey)[1]?.slice(-4) === ".png" ? (
								<img className="type-icon" src={captureIcon} alt="capture" data-tip data-for="capture-button" />
							) : (
								<img className="type-icon" src={downloadIcon} alt="download" data-tip data-for="download-button" />
							)} */}
							{x.mhtmlVal === "png" ? (
								<img className="type-icon" src={captureIcon} alt="capture" data-tip data-for="capture-button" />
							) : x.button.mode.type === "upload" ? (
								<img
								  className="type-icon"
								  src={uploadicon}
								  alt="upload"
								  data-tip
								  data-for="upload-button"
								/>
							  ) : (
								<img className="type-icon" src={downloadIcon} alt="download" data-tip data-for="download-button" />
							)}
							<ReactTooltip id="download-button" place="top" effect="solid">
								Download
							</ReactTooltip>
							<ReactTooltip id="capture-button" place="top" effect="solid">
								Screenshot
							</ReactTooltip>
							<ReactTooltip id="upload-button" place="top" effect="solid">
                                Upload
                            </ReactTooltip>
							{firstLetterCap(x.file.category)}
							<br />
						</div>
					</Link>
				)}
			</td>
			<td style={{ verticalAlign: "middle", padding: "10px", lineHeight: "1" }}>
				{x?.button?.shareonlyjson &&
				pdfGenerated(x.file.pdfdata) &&
				// x?.button?.mode?.type !== "capture" &&
				user?.email !== "dirolabs@gmail.com" &&
				x?.file?.json_v3_status !== "final-pdftojson" ? (
					<div className="cursor-pointer" onClick={onOpenDocView.bind(null, x)}  onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
						  onOpenDocView(x);
						}
					  }}
					  role="button"
					  tabIndex={0}>
						{verificationCell(x)}
					</div>
				) : (
					<Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
						{verificationCell(x)}
					</Link>
				)}
			</td>
			{/* <td style={{ verticalAlign: "middle", padding: "10px 0" }}>
               <Link to={"/pdf/" + makefileurl(x.sessionid)} >
                  <div style={{ display: "flex", justifyContent: "left", fontWeight: "400", fontSize: "14px", color: "black" }}>{getUserTime(x.file.eptime)}<br /></div>
               </Link>
            </td> */}
			<td onClick={sessiontoggle.bind(x.sessionid, index, x.sessionid, "sessiontoggle")}
			 style={{ verticalAlign: "middle", padding: "10px" }}>
				{/* <Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank"> */}
				<div
					style={{
						display: "flex",
						// justifyContent: "space-between",
						justifyContent: "start",
						alignItems: "center",
						fontSize: "14px",
						fontWeight: "400",
						lineHeight: "1",
						color: "black",
						marginRight: "10px",
						cursor: "pointer"
					}}
				>
					{x.button.livefeedbackMode === true ? (
						<i className="fa fa-circle  fa-sm mr-1 text-success"  data-tip data-for="true"></i>
					) : (
						<i className="fa fa-circle fa-sm mr-1 text-warning" data-tip data-for="false"></i>
					)}
					{truncate(x.file.docid)}
					

{x.button.livefeedbackMode?(
  <ReactTooltip id="true" place="top" effect="solid">
     Livefeedback On
  </ReactTooltip>
) : (
  <ReactTooltip id="false" place="top" effect="solid">
  Livefeedback off
  </ReactTooltip>
)}

      <span
          style={{
            marginLeft: "auto",
            fontSize: "14px",
            fontWeight: "400",
           
          }} > 
           <span className="ml-6"
		   
		   tabIndex={0} // Makes the element focusable
           onKeyDown={(e) => {
             if (e.key === 'Enter') {
               handleCopyDocID(x.file.docid);
             }
           }}
		   >
				<i
					className="fa fa-copy fa-sm"
					onClick={() => handleCopyDocID(x.file.docid)}
					onMouseLeave={() => setCopiedsessionid(false)}
					data-tip
					data-for="copy"
					style={{ color: 'gray', cursor: 'pointer' }} // Add cursor: 'pointer' for visual indication
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
						  handleCopyDocID(x.file.docid);
						}
					  }}
					  tabIndex="0"
					  role="button"
					
				></i>
			</span>
			
            <ReactTooltip id="copy" place="top" effect="solid">
                {copiedsessionid ? "Copied!" : "Copy"}
            </ReactTooltip>
            </span>



					{user?.email === "dirolabs@gmail.com" ? (
						<Link to={"/session-details/" + makefileurl(x.sessionid)} target="blank">
							{/* <Link to={{ pathname: "/session-details", state: "123111" }} target="blank" className=""> */}
							<i className="fa fa-info-circle icon-size" data-tip data-for="session-info">
								<ReactTooltip id="session-info" className="setting-tooltip" place="top" effect="solid">
									Session Info
								</ReactTooltip>
							</i>
						</Link>
					) : null}
				</div>
				{/* </Link> */}
			</td>
			{/* <td style={{ verticalAlign: "middle", padding: "10px 0" }}>
               <Link to={"/pdf/" + makefileurl(x.sessionid)} >
                  <div style={{ display: "flex", justifyContent: "left", fontWeight: "300", fontSize: "14px", color: "black" }}>Mismatch<br /></div>
               </Link>
            </td> */}
			<td style={{ verticalAlign: "middle", padding: "10px" }}>
				{x?.button?.shareonlyjson &&
				pdfGenerated(x.file.pdfdata) &&
				// x?.button?.mode?.type !== "capture" &&
				user?.email !== "dirolabs@gmail.com" &&
				x?.file?.json_v3_status !== "final-pdftojson" ? (
					<div className="cursor-pointer" onClick={onOpenDocView.bind(null, x)}  onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
						  onOpenDocView(x);
						}
					  }}
					  role="button"
					  tabIndex={0}>
						<div
							style={{
								textAlign: "left",
								display: "flex",
								justifyContent: "left",
								fontSize: "11px",
								lineHeight: "1",
								color: "black",
							}}
						>
							{x.file.remarks}
							<br />
						</div>
					</div>
				) : (
					<Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
						<div
							style={{
								textAlign: "left",
								display: "flex",
								justifyContent: "left",
								fontSize: "11px",
								lineHeight: "1",
								color: "black",
							}}
						>
							{x.file.remarks}
							<br />
						</div>
					</Link>
				)}
			</td>
			<td style={{ verticalAlign: "middle", padding: "10px", paddingBlock: "11px", lineHeight: "1" }}>
				{x?.file?.reasonforbaddoc ? (
					<>
						<span style={{ color: "red", fontSize: "13px", fontWeight: "400" }} data-tip data-for={x.file.reasonforbaddoc}>
							Bad document
						</span>
						<ReactTooltip id={x.file.reasonforbaddoc} place="top" effect="solid">
							{`Reason: ${x?.file?.reasonforbaddoc}`}
						</ReactTooltip>
					</>
				) : (
					x?.file?.pdfdata_v3?.map((item, index) =>
						item?.type === "name"
							? item?.values?.map((x, i) => (
									<span
										key={i}
										style={{
											display: "flex ",
											justifyContent: "space-between",
											alignItems: "center",
											fontSize: "13px",
											fontWeight: "400",
											lineHeight: "1",
											color: "black",
										}}
									>
										{x?.name}
									</span>
							  ))
							: ""
					)
				)}
			</td>

			<td style={{ verticalAlign: "middle", padding: "10px", lineHeight: "1" }}>
				<Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
					<span style={{ display: "flex", justifyContent: "left", paddingLeft: "10px", fontWeight: "400", fontSize: "14px", color: "black" }}  data-tip data-for={x.sessionid}>
						{getUserTime(x.file.eptime)}
					</span>
				</Link>
				<ReactTooltip id={x.sessionid} place="top" effect="solid">
					{`${new Date(x.file.eptime * 1).toDateString() + ", " + new Date(x.file.eptime * 1).toLocaleTimeString()}`}
				</ReactTooltip>
			</td>
			<td style={{ padding: "10px", verticalAlign: "middle" }} className="trackidClass">
				<div style={{ lineHeight: "1", justifyContent: "left", fontWeight: "400", fontSize: "12px", color: "black" }} className="trackidClass2">
					<React.Fragment>
						{x.warncase
							? x.warncase.map((wcase, index1) => (
									<React.Fragment key={index1}>
										<span key={index1}>
											{wcase.type === "trackid" ? (
												<React.Fragment>
													<span onClick={toggle.bind(null, x.warncase)}  onKeyDown={(e) => {
															if (e.key === "Enter" || e.key === " ") {
															toggle(x.warncase);
															}
														}}
														role="button"
														tabIndex={0} className="trackid-space">
														{wcase.keyword}
													</span>
													<br />
												</React.Fragment>
											) : (
												""
											)}
										</span>
									</React.Fragment>
							  ))
							: ""}
					</React.Fragment>
				</div>
			</td>
			<td style={{ alignItems: "center" }}>
				{/* <Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank">
               <div style={{ display: "flex", justifyContent: "left",paddingLeft: "10px", fontWeight: "400", fontSize: "14px", color: "rgb(81,185,217)" }}>
               <Button 
                 // className="btn btn-circle btn-sm btn-color-diro   text-white"
                  className="btn btn-without-bg "
                  type="submit"
                  value="submit"
                >
                  <i className="fas fa-file icon-size"></i>
                </Button>
               </div>
            </Link>

            <Button 
            onClick={openReportModal.bind(null,x.sessionid)}
                  //className="btn btn-circle btn-sm btn-color-diro   text-white"
                  className="btn btn-without-bg "
                  type="submit"
                  value="submit"
                  >
                     <i className="fas fa-info icon-size"></i>
            </Button> */}
				<div className="d-flex">
					<Button type="submit" onClick={openPdftojsonModal.bind(null, x.sessionid, x)} className="btnjson">
						JSON
					</Button>
					<UncontrolledDropdown>
						<DropdownToggle tag="span" onClick={togglebtn.bind(null, x.sessionid)} data-toggle="dropdown" aria-expanded={isOpen1}>
							<i className="fas fa-ellipsis-v cursor-pointer"></i>
						</DropdownToggle>
						<DropdownMenu className="data-table-dropdown">
							{/* <DropdownItem><Link to={"/pdf/" + makefileurl(x.sessionid)} target="blank"> View doc</Link></DropdownItem>   */}
							{x?.button?.shareonlyjson &&
							pdfGenerated(x.file.pdfdata) &&
							// x?.button?.mode?.type !== "capture" &&
							user?.email !== "dirolabs@gmail.com" &&
							x?.file?.json_v3_status !== "final-pdftojson" ? (
								<DropdownItem onClick={onOpenDocView.bind(null, x)}> View doc</DropdownItem>
							) : (
								<Link to={"/pdf/" + makefileurl(x.sessionid)} type="button" tabIndex="0" role="menuitem" className="dropdown-item" target="_blank">
									{" "}
									View doc
								</Link>
							)}

							<DropdownItem onClick={sessiontoggle.bind(x.sessionid, index, x.sessionid, "sessiontoggle")}> Session details</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={openReportModal.bind(x.sessionid)}> Report issue </DropdownItem>
							<DropdownItem onClick={deleteToggle.bind(null, x.sessionid)}> Delete session</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
			</td>
		</tr>
	));
	return (
		<React.Fragment>
			{rew_table}
			{dangerAlert.Alertdanger && (
				<SweetAlert
					danger
					showCancel
					confirmBtnText={!delSession? "Yes, delete it!" : "Deleting..."}
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title="Are you sure?"
					onConfirm={deleteSession.bind(this, dangerAlert.deletedsession)}
					onCancel={onDismissdeletesession.bind(null)}
					showLoaderOnConfirm={true}
					disabled={delSession}
				>
					You will not be able to recover this again!
				</SweetAlert>
			)}
			<Modal isOpen={modal} toggle={toggle1.bind(null)} className={props.className}>
				<ModalBody>
					<Row>
						<Col md="12">
							<Card style={{ fontWeight: "500", fontSize: "13px", paddingLeft: "20px", marginBottom: "0px" }}>
								<pre style={{ marginBottom: "0", height: "auto" }}>
									<React.Fragment>
										<Alert color="info" isOpen={copied} toggle={onDismiss.bind(null)}>
											Copied!
										</Alert>
										{modalData.alldata
											? modalData.alldata.map((wcase, index1) => (
													<React.Fragment key={index1}>
														<span key={index1}>
															{wcase.type === "trackid" ? (
																<React.Fragment>
																	<FormGroup>
																		<Label style={{ fontSize: "13px" }}>{wcase.message} </Label>
																		<Input type="textarea" row="50" value={wcase.keyword} />
																		<CopyToClipboard text={wcase.keyword} onCopy={copyclipboard.bind()}>
																			<span className="copy-clip">
																				{" "}
																				<i className="fa fa-copy"></i> Copy{" "}
																			</span>
																		</CopyToClipboard>
																	</FormGroup>
																</React.Fragment>
															) : (
																""
															)}
														</span>
													</React.Fragment>
											  ))
											: ""}
									</React.Fragment>{" "}
								</pre>
							</Card>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle1.bind(null)}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			{/* Request modal start here */}
			<Modal style={{ maxWidth: "50%" }} isOpen={reportModal} toggle={closeReportModal} className={className}>
				<ModalHeader toggle={closeReportModal}>Facing some issue? Let us know.</ModalHeader>
				<ModalBody>
					<Form onSubmit={onReportSubmit}>


					{submitFeedalert ? (
                <Col md="12" className="mt-4">
                  <Alert color="success" isOpen={true} >
                    Form submitted successfully
                  </Alert>
                </Col>
              ) : (
                ""
              )}

						<Row>
							<Col md="6">
								<FormGroup>
									<Label>Email address</Label>
									<Input type="text" value={emailId} onChange={(e) => onChange(e)} name="emailId" disabled />
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<Label>Session ID</Label>
									<Input type="text" value={sesId} onChange={(e) => onChange(e)} name="sesId" disabled />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="12">
								<FormGroup>
									<Label>Comment</Label>
									<Input
										type="textarea"
										placeholder="Please describe your issue in detail"
										onChange={(e) => onChange(e)}
										name="comment"
										value={comment}
										className={"form-control" + (err.comment ? " is-invalid" : "")}
									/>
									{err.comment ? <div className="invalid-feedback ">{err.comment}</div> : ""}
								</FormGroup>
							</Col>
						</Row>
						<Button type="submit" color="primary">
							Submit
						</Button>
					</Form>
				</ModalBody>
			</Modal>
			{/* Request modal ends here */}
			{/* session data modal starts here */}
			<Modal style={{ maxWidth: "1000px" }} isOpen={sessionModal} toggle={sessionToggleClose.bind(null)} className={className}>
				<ModalHeader toggle={sessionToggleClose.bind(null)} style={{alignItems: "center"}}>
					
				<div className="d-flex w-100 align-items-center justify-content-between">
                
                <div >
                  {sesId}
                </div>

                <div className="mx-auto text-center">
                  Session details
                </div>
        
                <div
                  onClick={openReportModal.bind(
                    null,
                    setsesid,
                    "openReportModal"
                  )}
                  style={{ cursor: "pointer" }}
				  onKeyDown={(e) => {
					if (e.key === 'Enter') {
						openReportModal.bind(
							null,
							setsesid,
							"openReportModal"
						  )
					}
				  }}
				  tabIndex="0"
				  role="button"
                >
                 Report issue
                </div>
              </div>
					
					</ModalHeader>
				<ModalBody>
					<Row>
						<Col md="12">
							<Card>
								<Table bordered responsive>
									<thead style={{ backgroundColor: "rgb(0, 188, 212)", color: "white", fontWeight: 300, fontSize: 14 }}>
										<tr>
											<th style={{ textAlign: "center" }} scope="row">
												Date & time
											</th>
											<th style={{ textAlign: "center" }} scope="row">
												Device/Browser
											</th>
											<th style={{ textAlign: "center" }} scope="row">
												Site
											</th>
											<th style={{ textAlign: "center" }} scope="row">
												Time spent
											</th>
											{/* <th style={{ textAlign: "center" }} scope="row">
												Verified
											</th>
											<th style={{ textAlign: "center" }} scope="row">
												Count not verify
											</th>
											<th style={{ textAlign: "center" }} scope="row">
												Try need help
											</th> */}
											<th style={{ textAlign: "center" }} scope="row">
												Final status
											</th>
											{viewdata?.exitForm?.selected && <th style={{ textAlign: "center" }} scope="row">
												Exit Reason
											</th>}
										</tr>
									</thead>
									<tbody>
										{session_report.length > 0 ? (
											<SessionReportTable session_report={session_report} viewdata={viewdata}  isSessionDetail={false} />
										) : (
											<tr>
												<td colSpan={12} className="text-center">
													No data found...
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={sessionToggleClose.bind(null)}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{/* session data modal ends here */}
			{/* Pdf to json modal start here */}
			{/* <Modal style={{ maxWidth: "80%" }} isOpen={pdftojsonmodal} toggle={closePdftojsonModal} className={className}>
				<ModalHeader toggle={closePdftojsonModal}>
					Json viewer
					<span className="right-json-button">
						<CopyToClipboard text={JSON.stringify(pdfjsondata)} onCopy={copyclipboardPdf.bind()}>
							<span className="cursor-pointer">
								<i className="fa fa-copy"></i> Copy
							</span>
						</CopyToClipboard>
					</span>
				</ModalHeader>
				<ModalBody>
					<Alert color="info" isOpen={copiedpdf} toggle={onDismiss.bind(null)}>
						Copied!
					</Alert>
					{pdf_loading ? (
						<Spinner />
					) : (
						// <ReactJson src={pdfjson} style={{ overflow:"auto"}}/>
						<pre style={{ marginBottom: "0", height: "75vh" }}>{JSON.stringify(pdfjsondata, null, 2)} </pre>
					)}
				</ModalBody>
			</Modal> */}
			<Modal style={{ maxWidth: "80%" }} isOpen={pdftojsonmodal} toggle={closePdftojsonModal} className={className}>
				<ModalHeader toggle={closePdftojsonModal} className="modal-header-custom">
					Extracted fields
					<span style={{ marginLeft: "10px" }}>{pdfDataType(jsonData?.file?.pdfdata)}</span>
					<span className="right-json-button">
						<CopyToClipboard
							text={jsonData?.file?.pdfdata_v3 ? JSON.stringify(jsonData.file.pdfdata_v3) : JSON.stringify(pdfjsondata)}
							onCopy={copyclipboardPdf.bind()}
						>
							<span className="cursor-pointer">
								<i className="fa fa-copy"></i> Copy
							</span>
						</CopyToClipboard>
					</span>
				</ModalHeader>
				<ModalBody className="p-0" style={{ height: "82vh", overflow: "auto" }}>
					<Nav tabs className="mb-1 mt-3 px-4">
						<NavItem>
							<NavLink
								className={activeTab === "1" ? "active" : ""}
								onClick={() => {
									setActiveTab("1");
								}}
							>
								<div className="cursor-pointer">Data</div>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={activeTab === "2" ? "active" : ""}
								onClick={() => {
									setActiveTab("2");
								}}
							>
								<div className="cursor-pointer">Raw json</div>
							</NavLink>
						</NavItem>
						{/* added the combine json tab  */}
						{(jsonData?.file?.combinedJSON ?? false) && ( // Check if combinedJSON data is available
						<NavItem>
							<NavLink
							className={activeTab === "3" ? "active" : ""}
							onClick={() => {
								setActiveTab("3");
							}}
							>
							<div className="cursor-pointer">Combine json</div>
							</NavLink>
						</NavItem>
					)}	

	{/* added the Transaction tab  */}
{showTransactionDataTab && (
  <NavItem>
    <NavLink
      className={activeTab === "4" ? "active" : ""}
      onClick={() => {
        setActiveTab("4");
      }}
    >
      <div className="cursor-pointer">Transaction data</div>
    </NavLink>
  </NavItem>
)}		
					</Nav>

					<Alert color="info" isOpen={copiedpdf} toggle={onDismiss.bind(null)}>
						Copied!
					</Alert>
					{loader ? (
						<Spinner />
					) : (
						
						<TabContent activeTab={activeTab} className="p-3 px-4">
						<TabPane tabId="1">
						  <Row>
							<Col sm="12">
							  {jsonData?.file?.pdfdata_v3 ? (
								<div className="border-table">
								  <Table responsive striped>
									<tbody>
									  {jsonData?.file?.pdfdata_v3.map((item, index) =>  //mapping the pdfdata keys
										item.values.map((x, i) => (
										  <React.Fragment key={i}>
											{Object.keys(x).map((key, j) => {
											  if (key === "requested" && x[key] === "") {  //hiding the requested and "" keys
												return null;
											  }
			
											  if (key === "namedetails" && Array.isArray(x[key])) { //check for the name details object
													return (
														<React.Fragment key={j}>
														{x[key].map((nameDetail, k) => (    //mapping the kyes of the name details
															<React.Fragment key={k}>
															<tr>
																<td colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
																Name Details {k + 1}
																</td>
															</tr>
															{Object.keys(nameDetail).map((nameKey, l) => (
																<tr key={l}>
																<td style={{ paddingLeft: '5rem' }}>{nameKey.replace(/_/g, ' ')}:</td>  {/*removing the underline from object keys */}
																<td>{nameDetail[nameKey]}</td>
																</tr>
															))}
															</React.Fragment>
														))}
														</React.Fragment>
													);
												}
												if (key === "accountdetails" && Array.isArray(x[key])) {
												return (
													<React.Fragment key={j}>
													{x[key].map((accountDetail, k) => (
														<React.Fragment key={k}>
														<tr>
															<td colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
															Account Details {k + 1}
															</td>
														</tr>
														{Object.keys(accountDetail).map((accountKey, l) => (
															<tr key={l}>
															<td style={{ paddingLeft: '5rem' }}>{accountKey.replace(/_/g, ' ')}:</td> {/*removing the underline from object keys */}
															<td>{accountDetail[accountKey]}</td>
															</tr>
														))}
														</React.Fragment>
													))}
													</React.Fragment>
												);
												} else {
												return (
												  <tr key={j}>
													<td>{key}:</td>
													<td>
													  {x[key] === "" || x[key] === null ? "N/A" : x[key]}  {/* displaying the remaining details object keys and value */}
													</td>
												  </tr>
												);
											  }
											})}
										  </React.Fragment>
										))
									  )}
									</tbody>
								  </Table>
								</div>
							  ) : jsonData?.file?.interimresponse ? (
								<div className="border-table">  {/*table for interim response */}
								  <Table responsive>
									<tbody>
									  {jsonData?.file?.interimresponse.map((item, index) =>
										item.values.map((x, i) => (
										  <React.Fragment key={i}>
											{Object.keys(x).map((key, j) => {
											  if (key === "requested" && x[key] === "") {
												return null;
											  }
											  if (key === "namedetails" && Array.isArray(x[key])) {  //check for the name details object
													return (
														<React.Fragment key={j}>
														{x[key].map((nameDetail, k) => (  //mapping the keys of the name details object
															<React.Fragment key={k}>
															<tr>
																<td colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
																Name Details {k + 1}
																</td>
															</tr>
															{Object.keys(nameDetail).map((nameKey, l) => (
																<tr key={l}>
																<td style={{ paddingLeft: '5rem' }}>{nameKey.replace(/_/g, ' ')}:</td> {/*removing the underline from object keys */}
																<td>{nameDetail[nameKey]}</td>
																</tr>
															))}
															
															</React.Fragment>
														))}
														</React.Fragment>
													);
													}
													if (key === "accountdetails" && Array.isArray(x[key])) {  //check for account details key
													return (
														<React.Fragment key={j}>
														{x[key].map((accountDetail, k) => (  //mapping the keys of the account details object
															<React.Fragment key={k}>
															<tr>
																<td colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
																Account Details {k + 1}
																</td>
															</tr>
															{Object.keys(accountDetail).map((accountKey, l) => (
																<tr key={l}>      
																<td style={{ paddingLeft: '5rem' }}>{accountKey.replace(/_/g, ' ')}:</td> {/*removing the underline from object keys */}
																<td>{accountDetail[accountKey]}</td>
																</tr>
															))}
															</React.Fragment>
														))}
														</React.Fragment>
												);
												}else {
												return (
												  <tr key={j}>
													<td>{key}:</td>
													<td>
													  {x[key] === "" || x[key] === null ? "N/A" : x[key]} {/* displaying the remaining details object keys and value */}
													</td>
												  </tr>
												);
											  }
											})}
										  </React.Fragment>
										))
									  )}
									</tbody>
								  </Table>
								</div>
							  ) : (
								<>
								  {pdfjsondata?.data?.message !== "Success!" ? (
									<h4 className="py-3">
									  Switch to Raw tab to view the data
									</h4>
								  ) : (
									<h4 className="py-3">
									  Processing result, please wait it may take up to 24 hours.
									</h4>
								  )}
								</>
							  )}
							</Col>
						  </Row>
						</TabPane>
						<TabPane tabId="2">
						  <Row>
							<Col sm="12">
							  <ReactJson
								src={
								  jsonData?.file?.pdfdata_v3
									? jsonData?.file?.pdfdata_v3
									: jsonData?.file?.interimresponse
									? jsonData?.file?.interimresponse   // displaying the interimresponse object keys and value
									: pdfjsondata?.data
								}
								collapsed={5}
								iconStyle={"circle"}
								displayDataTypes={false}
								collapseStringsAfterLength={75}
							  />
							</Col>
						  </Row>
						</TabPane>
						{/* added code for showing the combine json data if the combine json is available */}
						{jsonData && jsonData.file && jsonData.file.combinedJSON && (
							<TabPane tabId="3">
						  <Row>
							<Col sm="12">
							  <ReactJson
								src={
									jsonData.file.combinedJSON
								}
								collapsed={5}
								iconStyle={"circle"}
								displayDataTypes={false}
								collapseStringsAfterLength={75}
							  />
							</Col>
						  </Row>
						</TabPane>
						)}

{transactiondata && (
  <TabPane tabId="4" className="border-table">
    <Row>
      <Col sm="12">
        <Table responsive>
          <tbody>
            {Object.entries(transactiondata).map(([key, value], index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>
                  {typeof value === 'object' && value !== null ? (
                    JSON.stringify(value) // Convert object to string for rendering
                  ) : (
                    value
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  </TabPane>
)}
					  </TabContent>
					)}
				</ModalBody>
			</Modal>
			{/* Pdf to json modal ends here */}
			{docAlert.Alertdanger && (
				<SweetAlert
					confirmBtnText="Ok"
					confirmBtnBsStyle="warning"
					title="Document under processing, this might take upto 24 hours on weekdays."
					onConfirm={onDismissDocView.bind(null)}
					// onCancel={onDismissDocView.bind(null)}
				></SweetAlert>
			)}
			{badDocAlert && (
				<SweetAlert
					warning
					confirmBtnText="Ok"
					confirmBtnBsStyle="warning"
					title="Bad or irrelevant document submitted, no JSON was processed"
					onConfirm={onDismissBadDocView.bind(null)}
					// onCancel={onDismissDocView.bind(null)}
				></SweetAlert>
			)}
		</React.Fragment>
	);
};
RejectTable.propTypes = {
	sessionReport: PropTypes.func.isRequired,
	getLastClickedDocument: PropTypes.func.isRequired,
	table: PropTypes.object.isRequired,
	user: PropTypes.object,
	deleteSessionId: PropTypes.func.isRequired,
	requests: PropTypes.array,
	submitFeedbackData: PropTypes.func.isRequired,
	removeFeedbackAlert: PropTypes.func.isRequired,
	submitFeedalert: PropTypes.bool,
	getpdftojson: PropTypes.func.isRequired,
	viewdata: PropTypes.object,
	getExtractTransactionData : PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	table: state.table,
	auth: state.auth,
	viewdoc: state.viewdoc,
	btnSettingDelBtn: state.button.btnSettingDelBtn,
	submitFeedalert: state.table.submitFeedalert,
});

export default connect(mapStateToProps, {
	sessionReport,
	getLastClickedDocument,
	submitFeedbackData,
	removeFeedbackAlert,
	deleteSessionId,
	getpdftojson,
	getExtractTransactionData
})(RejectTable);